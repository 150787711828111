import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

import Header from "./../components/header";
import Footer from "./../components/footer";
import LoaderCircle from "./../components/loader-circle";
import BannerQuiz from "../components/BannerQuiz";
import { ValidateActivateToken } from "../services";
import { authLogout } from "../store/reducers/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import { startLoader, stopLoader } from "../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../utils/Helper";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import styles from "../styles/headerResponsive.module.css";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  loginAuth,
  refreshing,
  user,
  location,
  dispatch,
  ...rest
}) => {
  if (location.search) {
    console.log("hyelllo", location.search);
    const parsed = queryString.parse(location.search);
    if (parsed.doredirect == "true" && !isAuthenticated) {
      localStorage.setItem("refData", location.search);
    }
  }

  const [allreadyLogedin, setAllreadyLogedin] = useState(false);
  useEffect(() => {
    const validateUserToken = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        const result = await ValidateActivateToken({ token: token });
        console.log(result);
        if (result.data.code == 201) {
          setAllreadyLogedin(true);
          // dispatch(authLogout());
        }
      }
    };
    validateUserToken();
  }, []);

  const handleConformationLogin = async () => {
    dispatch(startLoader());
    try {
      dispatch(authLogout());
    } catch (error) {
      notifyError(error);
    } finally {
      dispatch(stopLoader());
    }
  };
  console.log(
    "verify-1",
    user.is_verified,
    isAuthenticated,
    user.is_session,
    user.is_blocked
  );
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LoaderCircle isLoading={refreshing} />
            {isAuthenticated &&
              user.is_verified != undefined &&
              !user.is_verified && <Redirect to="/verification-required" />}
            {isAuthenticated &&
              user.is_session != undefined &&
              !user.is_session && <Redirect to="/verification-required" />}
            {isAuthenticated ? (
              <main style={{ width: "100vw !important", overflow: "hidden" }}>
                {/* <BannerQuiz /> */}
                <Header />
                <SweetAlert
                  show={allreadyLogedin}
                  showConfirm
                  confirmBtnBsStyle="success"
                  // title="Please Read Carefully"
                  confirmBtnText={`Okay`}
                  onConfirm={() => handleConformationLogin()}
                  onEscapeKey={() => allreadyLogedin(false)}
                  onOutsideClick={() => allreadyLogedin(false)}
                  onCancel={() => allreadyLogedin(false)}
                >
                  <div
                    className="swal-title"
                    style={{
                      textAlign: "center",
                      padding: "0 30px",
                      fontWeight: "400",
                    }}
                  >
                    <p>
                      You have been logged out due to another login for the same
                      account on another device.{" "}
                      {/* <Tooltip title="Edit">
                        <i
                          style={{
                            marginTop: "3px",
                            marginLeft: "10px",
                            fontSize: "1.2rem",
                          }}
                          className="fas fa-info-circle instructions"
                        ></i>
                      </Tooltip> */}
                      <div className="notify-tool-tip nav-item">
                        <i
                          style={{
                            marginTop: "3px",
                            marginLeft: "10px",
                            fontSize: "1.2rem",
                          }}
                          className="fas fa-info-circle instructions"
                        ></i>
                        <span
                          className={classNames(
                            styles["hoverText-remaining"],
                            "hoverTextlogOut"
                          )}
                        >
                          We always recommend to not share credentials with
                          others to prevent such instances. Alternatively you
                          can ask your teammates to inform each other before
                          they proceed to login at their end
                        </span>
                      </div>
                      <br />
                    </p>
                  </div>
                </SweetAlert>
                <SweetAlert
                  show={user.is_blocked != undefined && user.is_blocked != 0}
                  showConfirm
                  confirmBtnBsStyle="success"
                  // title="Please Read Carefully"
                  confirmBtnText={`Okay`}
                  onConfirm={() => handleConformationLogin()}
                  // onEscapeKey={() => allreadyLogedin(false)}
                  // onOutsideClick={() => allreadyLogedin(false)}
                  // onCancel={() => allreadyLogedin(false)}
                >
                  <div
                    className="swal-title"
                    style={{
                      textAlign: "center",
                      padding: "0 30px",
                      fontWeight: "400",
                    }}
                  >
                    <p>
                      Your account has been blocked due to continous login from
                      different account to unblock it please contact the Prepai
                      team{" "}
                      {/* <Tooltip title="Edit">
                        <i
                          style={{
                            marginTop: "3px",
                            marginLeft: "10px",
                            fontSize: "1.2rem",
                          }}
                          className="fas fa-info-circle instructions"
                        ></i>
                      </Tooltip> */}
                      <div className="notify-tool-tip nav-item">
                        <i
                          style={{
                            marginTop: "3px",
                            marginLeft: "10px",
                            fontSize: "1.2rem",
                          }}
                          className="fas fa-info-circle instructions"
                        ></i>
                        <span
                          className={classNames(
                            styles["hoverText-remaining"],
                            "hoverTextlogOut"
                          )}
                        >
                          To unblock it please contact the management Team
                        </span>
                      </div>
                      <br />
                    </p>
                  </div>
                </SweetAlert>
                <Component {...props} />
                <Footer />
              </main>
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                  search: props.location.search,
                }}
              />
            )}
          </Suspense>
        );
      }}
    />
  );
};

PrivateRoute.displayName = "Private Routes";

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  loginAuth: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    loginAuth: store.auth.loginAuth,
    refreshing: store.generalActions.refreshing,
    user: store.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
